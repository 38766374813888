<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img" id="page-login">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 mx-auto self-center">
            <div style="width: 250px; margin: 0 auto 30px auto;">
                <img src="/watermark/watermark_dailian.png" style="width: 250px;">
            </div>
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
                            <div class="p-8">
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">로그인</h4>
                                </div>
                                <vs-input
                                    name="Id"
                                    icon="icon icon-user"
                                    icon-pack="feather"
                                    label-placeholder="Id"
                                    v-model="id"
                                    class="w-full no-icon-border"/>

                                <vs-input
                                    type="password"
                                    name="password"
                                    icon="icon icon-lock"
                                    icon-pack="feather"
                                    label-placeholder="Password"
                                    v-model="password"
                                    class="w-full mt-6 no-icon-border"
                                    @keyup.enter="onClick(id, password)" />

                                <div class="flex flex-wrap justify-between my-5">
                                    <vs-checkbox v-model="checkbox_remember_me" class="mb-3">아이디 저장</vs-checkbox>
                                </div>
                                <p><i>{{msg}}</i></p>
                                <vs-button class="float-right" @click="onClick(id, password)">Login</vs-button>

                                <vs-divider></vs-divider>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
// import axios from 'axios'
export default {
    data() {
        return {
            id: '',
            password: '',
            msg: '',
            checkbox_remember_me: false
        }
    },
    methods: {
      onClick(id, password) {
        //   const thisIns = this;
        this.$store.dispatch('LOGIN', {id, password})
          .then(() => {
            //   console.log(this)
            this.redirect()
          })
          .catch(({message}) => this.msg = message)
      },
      keydown(e){
          console.log('asdf', e);
      },
      redirect() {
        const {search} = window.location
        // const tokens = search.replace(/^\?/, '').split('&')
        // const {to} = tokens.reduce((qs, tkn) => {
        //   const pair = tkn.split('=')
        //   qs[pair[0]] = decodeURIComponent(pair[1])
        //   return qs
        // }, {})
        const tokens = [search.replace(/^\?/, '').replace('&', '?')];
        const {to} = tokens.reduce((qs, tkn) => {
          const pair = tkn.split('=')
          pair[1] = pair.slice(1).join('=');
          qs[pair[0]] = decodeURIComponent(pair[1])
          return qs
        }, {})
        console.log(to)
        if(to) this.$router.push(to)
        else {
            // if(this.$store.state.AppActiveUser.department == 'g1') { this.$router.push({path: '/newsListAll?department=g1'}) }
            // else if(this.$store.state.AppActiveUser.department == 'g2') { this.$router.push({path: '/newsListAll?department=g2'}) }
            // else if(this.$store.state.AppActiveUser.department == 'g3') { this.$router.push({path: '/newsListAll?department=g3'}) }
            // else if(this.$store.state.AppActiveUser.department == 'g4') { this.$router.push({path: '/newsListAll?department=g4'}) }
            // else if(this.$store.state.AppActiveUser.department == 'g5') { this.$router.push({path: '/newsListAll?department=g5'}) }
            // else if(this.$store.state.AppActiveUser.department == 'g6') { this.$router.push({path: '/newsListAll?department=g6'}) }
            // else if(this.$store.state.AppActiveUser.department == 'g7') { this.$router.push({path: '/newsListAll?department=g7'}) }
            // else if(this.$store.state.AppActiveUser.department == 'g8') { this.$router.push({path: '/newsListAll?department=g8'}) }
            // else if(this.$store.state.AppActiveUser.department == 'g9') { this.$router.push({path: '/newsListAll?department=g9'}) }
            // else if(this.$store.state.AppActiveUser.department == 'g10') { this.$router.push({path: '/newsListAll?department=g10'}) }
            // else if(this.$store.state.AppActiveUser.department == 'g11') { this.$router.push({path: '/newsListAll?department=g11'}) }
            // else if(this.$store.state.AppActiveUser.department == 'g12') { this.$router.push({path: '/newsListAll?department=g12'}) }
            if(this.$store.state.AppActiveUser.department && this.$store.state.AppActiveUser.department == 'g14') { 
                this.$router.push({path: '/newsListAll?department=g7'}) 
            }else if(this.$store.state.AppActiveUser.department && this.$store.state.AppActiveUser.department != 'g0') { 
                this.$router.push({path: '/newsListAll?department=' + this.$store.state.AppActiveUser.department}) 
            }
            else { this.$router.push({path: '/newsListAll?department=all'}) }
        }
      }
    },
    created() {
        // if (this.$store.getters.isAuthenticated){
        //     // console.log(this.$store.getters.isAuthenticated);
        //     console.log(this, this.$store.getters.isAuthenticated)
        //     this.redirect()
        // }
    }
}
</script>

<style lang="scss">
#page-login {
    .social-login {
        .bg-facebook {
          background-color: #1551b1;
        }
        .bg-twitter {
          background-color: #00aaff;
        }
        .bg-google {
          background-color: #1551b1;
        }
        .bg-github {
          background-color: #333;
        }
    }
}
</style>
